import React, { ReactNode } from 'react';
import { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';

import getPermissionsSSR from '@shared/ssrApiCalls/getPermissionsSSR';

import { getInternalAtsServiceApiHost } from '@packages/eh-utils/browserEnv';
import generateGetURL from '@packages/eh-utils/urlGenerator';
import OrganisationPageLayout from '@shared/OrganisationPageLayout';
import { TPermissions } from '@shared/hooks/useFetchPermissions';
import { ExtraLayoutProps } from '@shared/types';

import CareersPageDetail from 'src/modules/CareersPage/components/CareersPageDetail';
import {
  CountriesList,
  InitFetchData,
  JobsList,
  LandingPage,
  RecruitmentSettings,
  TeamList,
  TOrganisationPermisions,
} from 'src/modules/CareersPage/types';
import { fetchData } from 'src/shared/utils';

type OrgJobListProps = {
  orgFriendlyId: string;
  jobs: InitFetchData<JobsList>;
  countries: InitFetchData<CountriesList>;
  recruitmentSettings: InitFetchData<RecruitmentSettings>;
  teams: InitFetchData<TeamList>;
  landingPage: InitFetchData<LandingPage>;
  previewId: string | null;
  permissions?: TPermissions;
  organisationPermissions: InitFetchData<TOrganisationPermisions>;
};

const OrganisationJobListPage: NextPage<OrgJobListProps> = ({
  countries,
  jobs,
  orgFriendlyId,
  recruitmentSettings,
  teams,
  landingPage,
  previewId,
  permissions,
  organisationPermissions,
}) => {
  const { data } = recruitmentSettings;

  return (
    <>
      <Head>
        <title>{data?.company_name}</title>
        <link
          rel="canonical"
          href={data?.company_friendly_url}
          key="canonical"
        />
        <meta name="description" content={data?.company_overview} />

        {/* For Facebook and Open Graph */}
        <meta
          property="og:title"
          content={`Jobs available at ${data?.company_name}`}
        />

        <meta property="og:type" content="website" />

        <meta
          property="og:url"
          content={`https://jobs.employmenthero.com/organisations/${orgFriendlyId}`}
        />

        <meta
          property="og:image"
          content={data?.recruitment_logo?.unexpirable_logo_url}
        />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* For Twitter */}
        <meta
          name="twitter:title"
          content={`Jobs available at ${data?.company_name}`}
        />

        <meta name="twitter:description" content={data?.company_overview} />

        <meta
          name="twitter:image"
          content={data?.recruitment_logo?.unexpirable_logo_url}
        />

        <meta name="twitter:card" content="summary_large_image" />
      </Head>

      <CareersPageDetail
        orgId={orgFriendlyId}
        jobs={jobs}
        countries={countries}
        teams={teams}
        recruitmentSettings={recruitmentSettings}
        landingPage={landingPage}
        previewId={previewId}
        permissions={permissions}
        organisationPermissions={organisationPermissions}
      />
    </>
  );
};

export const getServerSideProps: GetServerSideProps<
  OrgJobListProps
> = async context => {
  const { req } = context;
  const orgFriendlyId = context?.query?.id ? context.query.id.toString() : '';
  const previewId = context?.query?.preview_id
    ? context.query.preview_id.toString()
    : null;

  const countriesUrl = `${getInternalAtsServiceApiHost()}/api/v1/career_page/organisations/${orgFriendlyId}/countries`;
  const teamsUrl = `${getInternalAtsServiceApiHost()}/api/v1/career_page/organisations/${orgFriendlyId}/teams`;
  const jobsUrl = generateGetURL(
    `${getInternalAtsServiceApiHost()}/api/v1/career_page/organisations/${orgFriendlyId}/jobs`,
    {
      item_per_page: 10,
      page_index: 1,
    }
  );
  const recruitmentSettingsUrl = generateGetURL(
    `${getInternalAtsServiceApiHost()}/api/v1/career_page/organisations/${orgFriendlyId}/recruitment_settings`,
    { preview_id: previewId }
  );
  const landingPageUrl = generateGetURL(
    `${getInternalAtsServiceApiHost()}/api/v1/career_page/organisations/${orgFriendlyId}/landing_page`,
    { preview_id: previewId }
  );
  const organisationPermissionsUrl = generateGetURL(
    `${getInternalAtsServiceApiHost()}/api/v1/career_page/organisations/${orgFriendlyId}/permissions`
  );

  const permissionsResponse = await getPermissionsSSR(req);

  if (!permissionsResponse?.data) {
    return { notFound: true };
  }

  const permissions = permissionsResponse.data;

  const recruitmentSettings: InitFetchData<RecruitmentSettings> =
    await fetchData(req, recruitmentSettingsUrl);

  if (recruitmentSettings?.errorCode) {
    return {
      notFound: true,
    };
  }

  const [countries, jobs, teams, landingPage, organisationPermissions] =
    await Promise.all([
      fetchData<CountriesList>(req, countriesUrl),
      fetchData<JobsList>(req, jobsUrl),
      fetchData<TeamList>(req, teamsUrl),
      fetchData<LandingPage>(req, landingPageUrl),
      fetchData<TOrganisationPermisions>(req, organisationPermissionsUrl),
    ]);

  return {
    props: {
      orgFriendlyId,
      countries,
      jobs,
      recruitmentSettings,
      teams,
      landingPage,
      previewId,
      permissions,
      organisationPermissions,
    },
  };
};

const Layout = ({
  children,
  pageProps,
}: {
  children: ReactNode;
  pageProps: ExtraLayoutProps;
}) => (
  <OrganisationPageLayout pageProps={pageProps}>
    {children}
  </OrganisationPageLayout>
);

Object.assign(OrganisationJobListPage, { Layout });

export default OrganisationJobListPage;
