import React from 'react';
import { useTheme } from 'styled-components';

import { Box, Typography } from '@hero-design/react';

import OrgLogo, { OrgLogoSize } from '../OrgLogo';

const OrgNavBar = ({
  logoUrl,
  companyName,
}: {
  logoUrl: string;
  companyName: string;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: 86,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `${theme.colors.defaultLightBackground}`,
        borderBottom: `${theme.borderWidths.base}px solid ${theme.colors.defaultBorder}`,
        gap: `${theme.space.small}px`,
      }}
    >
      <OrgLogo orgLogoUrl={logoUrl} size={OrgLogoSize.navBar} />
      <Typography.Title level={2}>{companyName}</Typography.Title>
    </Box>
  );
};

export default OrgNavBar;
