import { filter } from 'lodash/fp';
import isEmpty from 'lodash/fp/isEmpty';

import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import styled from 'styled-components';

import { Box, Spinner } from '@hero-design/react';
import useInspectletTracking from '@shared/trackTools/inspectlet/useInspectletTracking';

import { getInspectletId } from '@packages/eh-utils/browserEnv';
import AppBanner from '@shared/AppBanner';
import NavBar from '@shared/NavBar';
import OrgNavBar from '@shared/OrgNavBar';
import SiteFooter from '@shared/SiteFooter';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import { getMobileDetector } from '@shared/utils/getMobileDetector';
import { getSwagNavBarItems } from '@shared/utils/getSwagNavBarItems';
import { useMixpanelTracking } from '@shared/trackTools/mixpanel';
import { ExtraLayoutProps } from '@shared/types';

import AppDownloadBanner from 'src/modules/CareersPage/components/LandingPage/AppDownloadBanner';
import useGetUnreadNotificationCounter from 'src/modules/notifications/hooks/useGetUnreadNotificationCounter';
import { SWAG_IN_APP_REFERRER } from 'src/constants';

import { LANDING_PAGE_TYPES, STATUSES } from './constants';

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

type PageLayoutProps = {
  children: ReactNode;
  pageProps?: ExtraLayoutProps;
};

const OrganisationPageLayout = ({ children, pageProps }: PageLayoutProps) => {
  const router = useRouter();
  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';
  const { track } = useMixpanelTracking();
  const { permissionsData, isFetchingPermissions } = useFetchPermissions();
  const careerCompanyPageSlice2Enabled =
    permissionsData?.data?.career_company_page_slice_2_enabled;

  const { data: unreadNotificationData } = useGetUnreadNotificationCounter({});

  const recruitmentSettings = pageProps?.recruitmentSettings?.data;
  const landingPage = pageProps?.landingPage?.data;
  const previewId = pageProps?.previewId;

  const landingPageCareerPublished =
    landingPage?.status === STATUSES.PUBLISHED_TO_CAREER_PAGE;
  const isLandingPagePreview = !isEmpty(previewId);
  const visibleContentBlocks =
    filter(({ visible_on_page = false }) => visible_on_page)(
      landingPage?.content_blocks
    ) ?? [];

  const orgCareerPageSetUp =
    careerCompanyPageSlice2Enabled &&
    (landingPageCareerPublished || isLandingPagePreview) &&
    visibleContentBlocks.length > 0 &&
    recruitmentSettings?.recruitment_logo?.logo_url;

  const [careerPageVisited, setCareerPageVisited] = useState(false);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      setCareerPageVisited(true);
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, []);

  useEffect(() => {
    if (
      careerCompanyPageSlice2Enabled &&
      careerPageVisited &&
      router.pathname === '/organisations/[id]'
    ) {
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, ...{ referrer: SWAG_IN_APP_REFERRER } },
      });
    }
  }, [router.pathname, careerPageVisited, careerCompanyPageSlice2Enabled]);

  const showOrgNavBar = useMemo(
    () =>
      router.query.referrer !== SWAG_IN_APP_REFERRER &&
      orgCareerPageSetUp &&
      !careerPageVisited,
    [router.query.referrer, orgCareerPageSetUp, careerPageVisited]
  );

  const isNotHideHeaderNav =
    landingPage?.type !== LANDING_PAGE_TYPES.TALENT_LANDING_PAGE;

  useInspectletTracking(getInspectletId());

  if (isFetchingPermissions) {
    return <Spinner />;
  }

  return (
    <Box style={{ flex: 1 }}>
      <Box>
        {getMobileDetector()?.isMobile && <AppDownloadBanner isMobile />}
      </Box>

      <PageContainer>
        {isNotHideHeaderNav && (
          <Box data-test-id="header-nav-bar">
            {showOrgNavBar ? (
              <OrgNavBar
                logoUrl={recruitmentSettings?.recruitment_logo?.logo_url ?? ''}
                companyName={recruitmentSettings?.company_name ?? ''}
              />
            ) : (
              <NavBar
                navBarItems={getSwagNavBarItems({
                  permissionsData,
                  isAuthenticated,
                  track,
                  notificationsCount: unreadNotificationData,
                })}
              />
            )}
          </Box>
        )}

        <AppBanner />
        <StyledDiv>{children}</StyledDiv>

        <Box>
          <SiteFooter />
        </Box>
      </PageContainer>
    </Box>
  );
};

export default OrganisationPageLayout;
